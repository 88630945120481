import {GogoCmsEnvironmentInterface} from "@sv/sv-common/lib/env/interfaces/GogoCmsEnvironmentInterface";

export const environment: GogoCmsEnvironmentInterface = {
  options: {
    production: true,
  },
  apis: {},
  links: {
    IDP: 'api-gateway/oauth2/authorization/intersim',
    GOGOBOT_FRONTEND: "https://staging.app.gogobot.ch",
  },
  mock: {
    BUCKET: 'schulverlag-stage',
  },
};
